


































import {Component, Vue} from "vue-property-decorator"
import {getModule} from "vuex-module-decorators";
import LangModule from "@/store/LangModule";

@Component
export default class ContactComponent extends Vue {
	lang: any = getModule(LangModule).lang
}
